import React from "react";
import { graphql, Link } from "gatsby";
import slugify from "slugify";
import Seo from "../layout/seo";
import Layout from "../layout";
import RecipesList from "../components/recipesList";
import "../styles/main.scss";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = ({ data }) => {
  const recipes = data.allNotion.nodes;
  const categories = data.allMarkdownRemark.group.sort(
    (b, a) => a.pageInfo.itemCount - b.pageInfo.itemCount
  );
  return (
    <Layout>
      <Seo />
      <div data-component="accueil" className="container">
        <div
          className="section is-small index-title"
          style={{ display: "grid" }}
        >
          <StaticImage
            style={{
              gridArea: "1/1",
              maxHeight: 250,
            }}
            layout="fullWidth"
            alt=""
            src={"../images/main.jpeg"}
            formats={["auto", "webp", "avif"]}
          />
          <div
            className="text"
            style={{
              gridArea: "1/1",
              position: "relative",
              margin: "auto",
              marginLeft: "calc(25% + 1.5rem)",
            }}
          >
            <h1 className="title is-1">Bienvenue,</h1>
            <h2 className="subtitle">
              vous trouverez ici des recettes, rien de plus.
            </h2>
            <h3 className="subtitle">Régalons nous !</h3>
          </div>
        </div>
        <div className="section">
          <div className="columns">
            <div className="column is-one-quarter col-bordered categories">
              <h3 className="title">
                <Link className="has-text-dark" to="categories">
                  Catégories
                </Link>
              </h3>
              <div className="field is-grouped is-grouped-multiline">
                {categories.map((categorie) => (
                  <Link
                    to={`/${slugify(categorie.fieldValue, {
                      strict: true,
                      lower: true,
                    })}`}
                    className="control"
                    key={categorie.fieldValue}
                  >
                    <div className="tags are-medium has-addons">
                      <div className="tag is-dark">
                        {categorie.pageInfo.itemCount}
                      </div>
                      <div className="tag is-primary">
                        {categorie.fieldValue}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="column is-three-quarter recettes">
              <h3 className="title ml-3">
                <Link className="has-text-dark" to="recettes">
                  Recettes
                </Link>
              </h3>
              <RecipesList recettes={recipes} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allNotion(sort: { fields: properties___Ajout_e_le___value }) {
      nodes {
        id
        childMarkdownRemark {
          frontmatter {
            title

            ajouteLe: Ajout_e_le(fromNow: true, locale: "fr")
            tags: Cat_gories {
              id
              name
            }
          }
        }
        raw {
          imageFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: TRACED_SVG
                layout: CONSTRAINED
                width: 1024
              )
            }
          }
        }
      }
    }
    allMarkdownRemark {
      group(field: frontmatter___Cat_gories___name) {
        fieldValue
        pageInfo {
          itemCount
        }
      }
    }
  }
`;
export default IndexPage;
